import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./projects.css"

const Projects = () => (
  <Layout>
    <SEO title="Projects" />
  
    <h3 id="header"><a href="https://actualsizevisualizer.com"><i>Actual Size Visualizer</i></a></h3>
    
    <p id="description">Visualizes dimensions in their actual size for both length and area</p>
   
  </Layout>
)

export default Projects